.custom-row {
  margin-top: 10px;
}

.custom-space {
  margin-top: 10px;
}

.custom-title {
  margin-top: 25px;
  margin-bottom: 20px;
}

.custom-space {
  margin-bottom: 20px;
}
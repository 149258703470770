.custom-row {
  margin-top: 20px;
}

.custom-title {
  margin-top: 25px;
}

.custom-space {
  margin-top: 25px;
  margin-bottom: 20px;
}

.custom-button {
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

.custom-container {
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}